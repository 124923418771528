<template>
    <b-modal
      id="modal-primary-order"
      hide-footer
      modal-class="modal-primary"
      centered
      size="lg"
      :title="'Cotizacion # 0000'+computedOrder.id_order"
      ref="modalCategoryForm" @close="editCategoryHidden" >
            <InvoiceComponent :order="computedOrder" />
    </b-modal>
</template>
<script>
  import { ref, computed, defineComponent } from '@vue/composition-api';
import {
  BTab, BTabs, BCard, BAlert, BLink, BImg, BRow, BCol, BModal
} from 'bootstrap-vue';
import InvoiceComponent from '@/lavarclick/components/billing/invoice/InvoiceComponent.vue';

export default  defineComponent({

    components: {
        InvoiceComponent, BTab, BTabs, BCard, BAlert, BLink, BImg, BRow, BCol, BModal

    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    setup(props, {emit}) {

        let orderActive = ref(null)

        const computedOrder = computed({
            get: () => {                
                orderActive.value = props.order;
                return props.order;
            }
        })

        return {
            orderActive,
            computedOrder,
            
        }
    },
    created() {
        console.log('', this.computedOrder);

    },
    methods: {
        editCategoryHidden(eventx) {
            
            this.$emit('closeOrderDescriptor', {});

        },
    }
});
</script>
<style>
</style>
