<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Estadistica Desempeño</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD" style="width: 200px !important;"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <BarChartComponent
        :height="200"
        :data="chartjsData.data"
        :options="chartjsData.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import BarChartComponent from './BarChartComponent.vue'
import moment from 'moment';
import TimmingManager from '@/lavarclick/ecommerce/TimmingManager.ts';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    BarChartComponent,
  },
  props: {
    chartjsData: {}
  },
  data() {
    return {
      
      rangePicker: [moment(new Date()).format('YYYY-MM-DD'), new TimmingManager().getPastDateFromNow(15)],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
