import moment from 'moment';

export default class TimmingManager {

    constructor() {


    }

    daysBetweenDates(start_date, end_date) {
        return moment(end_date).diff(moment(start_date), 'days');
    }

    getPastDateFromNow(days) {
        return moment(new Date()).subtract(days, 'days').format('YYYY-MM-DD');
    }

    getPastDateListFromNow(days, flagWOutYear) {

        const dateList = [];

        for(let i = 0; i < days; i++) {

            if(flagWOutYear) {
                dateList.push(moment(new Date()).subtract(i, 'days').format('MM-DD'));
            }else {
                dateList.push(moment(new Date()).subtract(i, 'days').format('YYYY-MM-DD'));
            }

        }
        return dateList;
    }
}