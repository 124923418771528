<template>

  <!-- Table Container Card -->
  <b-card
    no-body style="height: 100% !important"
  >

    <div class="">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entradas</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <!-- <b-button
            variant="primary"
            @click="getCustomer(1)"
          >
            Add Record
          </b-button> -->
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="computedQuerySearch"
              class="d-inline-block mr-1"
              placeholder="Busca un Usuario ..."
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Filtro x Estado"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table style="width: 100% !important;"
      ref="refInvoiceListTable"
      :items="computedFilterUsers"
      responsive
      :fields="[
    { key: 'id_customer', label: 'ID', sortable: true },
    // { key: 'invoiceStatus', sortable: true },
    { key: 'names', sortable: true, label: 'Usuario' },
    // { key: 'total', sortable: true, formatter: val => `$${0}` },
    // { key: 'issuedDate', sortable: true },
    { key: 'created_at', sortable: true, label: 'Tipo' },
    // { key: 'balance', sortable: true },
    { key: 'actions', label: 'Acciones' },
  ]"
      primary-key="id_customer"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No se Encontraron Empleados que Coinciden"
      :sort-desc.sync="isSortDirDesc"
      class="serviceTableStyle"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id_customer)="data">
        
          {{ data.value }}
        
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(invoiceStatus)="data">
        <b-avatar
          :id="`invoice-row-${data.item.id_customer}`"
          size="32"
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).variant}`"
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${data.item.id_customer}`"
          placement="top"
        >
          <p class="mb-0">
            {{ data.item.invoiceStatus }}
          </p>
          <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Client -->
      <template #cell(names)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="data.item.avatar"
              :variant="`light-${resolveClientAvatarVariant(data.item.invoiceStatus)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap" >
            
        <span style="font-size: 16px; font-weight: bold;">{{ data.item.names }} {{ data.item.surnames }}</span>
          </span>
          <span class="text-muted">{{ data.item.email }}</span><br/>350 4888077 (Bogota, Colombia)
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(created_at)="data">
        <span class="text-nowrap">
        <b>
            {{data.item.type}}</b><br/>
            {{new Date(data.item.created_at/1).toISOString().split('T')[0]}}<br/>
            {{new Date(data.item.created_at/1).toISOString().split('T')[1]}}
<!--           
          {{ new Date(data.value/1).toISOString().split('.')[0].split('T')[1].lastIndexOf('5') === -1 ? 'Aliado' : 'Cliente' }}</b><br/>
          {{ new Date(data.value/1).toISOString().split('.')[0].split('T')[0] }} {{ new Date(data.value/1).toISOString().split('.')[0].split('T')[1] }} -->
          
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(balance)="data">
        <template v-if="data.value === 0">
          <b-badge
            pill
            variant="light-success"
          >
            Paid
          </b-badge>
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id_customer}-send-icon`"
            icon="SendIcon"
            class="cursor-pointer"
            size="16"
          />
          <b-tooltip
            title="Enviar"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id_customer}-send-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id_customer}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="describeUser(data.item)"
            style="cursor: pointer !important;"
          />
          <b-tooltip
            title="Vista Detallada"
            :target="`invoice-row-${data.item.id_customer}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="data.item.remember_token === ''">
              <feather-icon icon="DownloadIcon"  />
              <span class="align-middle ml-50" @click="$emit('updateUserStatus', {
                id_user: data.item.id_scanclick_users,
                status: 1
              })">Habilitar</span>
            </b-dropdown-item>
            <b-dropdown-item v-else>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50" @click="$emit('updateUserStatus', {
                id_user: data.item.id_scanclick_users,
                status: 0,
                
              })">Deshabilitar</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id_customer } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item> -->
            
            <!-- <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} Documentos</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted, defineComponent, ref, computed } from '@vue/composition-api'
import useInvoicesList from './useInvoiceList'
import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts'
import invoiceStoreModule from './invoiceStoreModule'
import store from '@/store/index';
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
  },
  props: {

    employeePartners: Array

  },
  methods: {

    getCustomer(idCustomer) {

      this.accountsManager.getUser(20).then((user) => {
        // console.log(user);
      });

    }

  },
  setup(props, {emit}) {
    const describeUser = (userDetail) => { emit('describeUser', userDetail)}; 
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Activos',
      'Inactivos',
      'Pausados',
      'Ordenes Activas'
    ]

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      statusFilter,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      accountsManager
    } = useInvoicesList()

    let filterableUsers = ref([]);

    let computedEmployeePartners = computed({
        get: () => { return props.employeePartners; },
      });

    let computedFilterUsers = computed({
        get: () => { return filterableUsers.value; },
        set: (orders) => {
          filterableUsers.value = orders;
        }
      });
      
      let search_query = ref('');
      const ecommerce = ref(new ECommerce());

      const computedQuerySearch = computed({
      get: function() { 
         
        console.log('cambiando ', search_query, computedFilterUsers, computedEmployeePartners.value); return search_query.value},
      set: function(querySearch) { 
        search_query.value = querySearch;
        
          if(querySearch !== '') {

            ecommerce.value.filterUsers(computedEmployeePartners.value, search_query.value).then((orderMatch) => {

              computedFilterUsers.value = orderMatch;

            });
          }else {

            computedFilterUsers.value = computedEmployeePartners.value;

          }
        }
      });
    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      statusFilter,
      describeUser,
      refetchData,
      statusOptions,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      accountsManager: new AccountsManager(),
      computedFilterUsers,
      computedQuerySearch

    }
  },
  created() {

    this.computedQuerySearch = '';

  }

});
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

  
.serviceTableStyle {
        margin-left: 0px !important;
        border-bottom-style: solid !important;
        border-bottom-width: 1px !important;
        border-collapse:collapse; 
        font-family: 'MavenPro' !important;
        border-radius: 10px !important; 
        width: 100% !important;
        margin-top: 10px !important;
        overflow-y: auto !important;
        height: 100% !important;

    }
    
    
    .serviceTableStyle th{ 
        border:#4e95f4 0px solid;
        font-size: 14px !important;
        color: #ffffff !important;
        background-color: #6d36ac !important;
        text-align: justify !important;
        font-family: 'MavenPro' !important;
    }
    
    .serviceTableStyle td{ 
        border:#4e95f4 0px solid;
        text-align: justify !important;
        font-family: 'MavenPro' !important;
    
    }
    /* provide some minimal visual accomodation for IE8 and below */
    .serviceTableStyle tr{
        background: #b8d1f3;
    }
    /*  Define the background color for all the ODD background rows  */
    .serviceTableStyle tr:nth-child(odd){ 
        background: #f2edf7;
    }
    /*  Define the background color for all the EVEN background rows  */
    .serviceTableStyle tr:nth-child(even){
        background: #f2faf0;
    }
</style>
