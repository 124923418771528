export default class User {

    constructor() {
        return {
            id: 0,
            fullName: '',
            username: '',
            avatar: '',
            email: '',
            role: 'Admin',
            ability: [
                {
                    action: '',
                    subject: ''
                }
            ],
            extras: {
                eCommerceCartItemsCount: 0
            },
            customer: {
                id_scanclick_customers: 0,
                fk_scanclick_users_customers_admin: 0,
                fk_scanclick_subscriptions_customers: 0,
                created_at: '',
                paused_at: '',
                suspended_at: '',
                name: '',
                gov_id: '',
                logo: '',
                debt: 0,
                admin: {
                    avatar: '',
                    company: '',
                    created_at: '',
                    email: '',
                    email_verified_at: '',
                    fk_user_customization: '',
                    id_scanclick_users: 0,
                    password: '',
                    names: '',
                    remember_token: '',
                    surnames: '',
                    updated_at: ''
                },
                subscription: {
                    id_scanclick_subscriptions: 0,
                    name: '',
                    plan_name: '',
                    unit_price: 0,
                    discount_stage_one: 0,
                    discount_stage_two: 0,
                    discount_stage_three: 0
                }
            },
            scanclick_user: {
                avatar: '',
                company: '',
                created_at: '',
                email: '',
                email_verified_at: '',
                fk_user_customization: '',
                id_scanclick_users: 0,
                password: 0,
                names: 0,
                remember_token: 0,
                surnames: 0,
                updated_at: 0
            },
            user_info: {
                dob: null,
                mobile: '',
                website: '',
                language: '',
                gender: '',
                contactOptions: ['Email', 'Message', 'Phone'],
                addressLine1: '',
                addressLine2: '',
                postcode: '',
                city: '',
                state: '',
                country: '',
                socialinfo: {
                    twitter: '',
                    facebook: '',
                    instagram: '',
                    github: '',
                    codepen: '',
                    slack: '',
                }
            }
        }
    }
}