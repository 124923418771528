var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticStyle:{"padding-left":"15px !important","width":"25% !important"}},[_vm._v(" Pais "),_c('b-form-group',[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countries,"label":"name_spanish","clearable":false},on:{"input":_vm.setCountry}})],1)],1),_c('div',{staticStyle:{"padding-left":"15px !important","width":"25% !important"}},[_vm._v(" Ciudad "),(_vm.flagCities)?_c('v-select',{attrs:{"label":"city_name","options":_vm.cities,"clearable":false},on:{"input":_vm.setCity}}):_vm._e()],1),_c('div',{staticStyle:{"padding-left":"15px !important","width":"25% !important"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","type":"email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})],1)],1),_c('div',{staticStyle:{"padding-left":"15px !important","width":"25% !important"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"user-status"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-status"},model:{value:(_vm.userData.status),callback:function ($$v) {_vm.$set(_vm.userData, "status", $$v)},expression:"userData.status"}})],1)],1)]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticStyle:{"padding-left":"15px !important","width":"22% !important"}},[_c('b-form-group',{attrs:{"label":"Documento","label-for":"full-name"}},[_c('b-form-input',{staticStyle:{"width":"100%"},attrs:{"id":"full-name"},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.setGovId.apply(void 0, [ 0 ].concat( argsArray ))}}})],1)],1),_c('div',{staticStyle:{"padding-left":"10px !important","width":"24% !important"}},[_c('b-form-group',{attrs:{"label":"Nombre(s)","label-for":"username"}},[_c('b-form-input',{attrs:{"id":"username"},model:{value:(_vm.userData.username),callback:function ($$v) {_vm.$set(_vm.userData, "username", $$v)},expression:"userData.username"}})],1)],1),_c('div',{staticStyle:{"padding-left":"10px !important","width":"24% !important"}},[_c('b-form-group',{attrs:{"label":"Apellido(s)","label-for":"full-name"}},[_c('b-form-input',{attrs:{"id":"full-name"},model:{value:(_vm.userData.fullName),callback:function ($$v) {_vm.$set(_vm.userData, "fullName", $$v)},expression:"userData.fullName"}})],1)],1),_c('div',{staticStyle:{"padding-left":"15px !important","width":"29% !important"}},[_c('b-form-group',{attrs:{"label":"Area","label-for":"mobile"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.customerAreasList,"label":"name","clearable":false},on:{"input":_vm.setUserArea}})],1)],1),_c('div',{staticStyle:{"margin-top":"0%","margin-left":"2%","width":"100%"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Banco ","label-for":"full-name"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.computedBankList,"label":"name","clearable":false},on:{"input":_vm.setUserBank}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"# Cuenta ","label-for":"full-name"}},[_c('b-form-input',{staticStyle:{"width":"100%"},attrs:{"id":"full-name"},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.setGovId.apply(void 0, [ 1 ].concat( argsArray ))}}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Compañia","label-for":"company"}},[_c('b-form-input',{attrs:{"id":"company"},model:{value:(_vm.userData.company),callback:function ($$v) {_vm.$set(_vm.userData, "company", $$v)},expression:"userData.company"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Contraseña Primera Vez","label-for":"username"}},[_c('b-form-input',{attrs:{"id":"username"},model:{value:(_vm.userData.newPassword),callback:function ($$v) {_vm.$set(_vm.userData, "newPassword", $$v)},expression:"userData.newPassword"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{staticStyle:{"width":"110%"},attrs:{"label":"Catalogo de Servicios","label-for":"contact-options","label-class":"mb-1"}},_vm._l((_vm.computedServicesList),function(option,indexOp){return _c('b-form-checkbox',{key:indexOp,staticClass:"col-2 materialCheckbox",attrs:{"value":indexOp,"name":"flavourMaterial","inline":""},on:{"input":function($event){return _vm.updateCategoriesRelated(indexOp)}}},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"0.9em !important"}},[_vm._v(_vm._s(option.name))])])}),1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}})],1)],1)])]),_c('b-form',[(_vm.flag)?_c('b-row',[_c('b-col',{attrs:{"xl":"12"}},[_c('div',{staticStyle:{"width":"100%","height":"400px"}},[_c('MapBasicPosition',{attrs:{"locate_service_provider":{
                customer: {
                  location: {
                    lat: 4.7032197228260175,
                    lng: -74.03747129203830,
                  },
                  full_name: 'nuevo '
                }
              }},on:{"setNewLocation":_vm.setNewLocation}})],1)])],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"4"}}),_c('b-col',{staticStyle:{"padding-top":"0%"},attrs:{"md":"12"}},[(_vm.userData.id !== 0)?_c('div',[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.updateUserData}},[_vm._v(" Guardar Cambios ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Cancelar ")])],1):_c('div',{staticStyle:{"padding-top":"0% !important"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.updateUserData}},[_vm._v(" Crear Usuario ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Limpiar ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }