<template>
  <b-form v-if="flag">
    <b-row>
      <b-col xl="12">
        
      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Dirección
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">

        <!-- Field: Address Line 1 -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Direccion Residencia"
            label-for="address-line-1"
          >
            <b-form-input
              id="address-line-1"
              v-model="userDataInfo.postal_address"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Address Line 2 -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Direccion Alternativa"
            label-for="address-line-2"
          >
            <b-form-input
              id="address-line-2"
              v-model="userDataInfo.addressLine2"
              placeholder=""
            />
          </b-form-group>
        </b-col>

        <!-- Field: Postcode -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Codigo Postal"
            label-for="postcode"
          >
            <b-form-input
              id="postcode"
              v-model="userDataInfo.postcode"
              type="number"
              placeholder=""
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Ciudad"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="userDataInfo.city"
            />
          </b-form-group>
        </b-col>

        <!-- Field: State -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Departamento"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="userDataInfo.state"
              placeholder=""
            />
          </b-form-group>
        </b-col>

        <!-- Field: Country -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Pais"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="userDataInfo.country"
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      </b-col>
      <b-col xl="12">
        <div style="width: 100%; height: 400px;">
                  <MapBasicPosition @setNewLocation="setNewLocation" :locate_service_provider="{
                customer: {
                  location: {
                    lat: 4.7032197228260175,
                    lng: -74.03747129203830,
                  },
                  full_name: 'nuevo '
                }
              }" />  </div>
      </b-col>
      <b-col
        v-for="socialField in socialInputs"
        :key="socialField.dataField"
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="socialField.label"
          :label-for="socialField.dataField"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                size="16"
                :icon="socialField.icon"
              />
            </b-input-group-prepend>
            <b-form-input
              :id="socialField.dataField"
              v-model="userDataInfo.socialinfo[socialField.dataField]"
              type="url"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col class="mt-2" v-if="userData.id !== 0">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="updateUserData"
        >
          Guardar Cambios
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Cancelar
        </b-button>
      </b-col>
      <b-col class="mt-2" v-else>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="updateUserData"
        >
          Agregar Informacion Nuevo Usuario
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import MapBasicPosition from '@/lavarclick/components/maps/MapBasicPosition.vue'; 
export default {
  components: {
    BRow, BCol, BForm, MapBasicPosition,BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
  },
  props: {

    userData: {}

  },
  setup() {
    let userDataInfo = {};
    let flag = false;

    // ? dataField is field name of `userDataSocial` object
    const socialInputs = [
      {
        icon: 'TwitterIcon',
        dataField: 'twitter',
        label: 'Twitter',
      },
      {
        icon: 'FacebookIcon',
        dataField: 'facebook',
        label: 'facebook',
      },
      {
        icon: 'InstagramIcon',
        dataField: 'instagram',
        label: 'instagram',
      },
      {
        icon: 'GithubIcon',
        dataField: 'github',
        label: 'github',
      },
      {
        icon: 'CodepenIcon',
        dataField: 'codepen',
        label: 'codepen',
      },
      {
        icon: 'SlackIcon',
        dataField: 'slack',
        label: 'slack',
      },
    ]

    return {
      userDataInfo,
      socialInputs,
      flag
    }
  },
  mounted() {
    this.userDataInfo = this.userData.user_info;
    this.flag = true;
  },
  methods: {
    setNewLocation(newLocation) {
        this.userData.location.lat = newLocation.lat;
        this.userData.location.lng = newLocation.lng;
        console.log(this.userData);
      },  

    updateUserData() {
      this.$emit('updateUserInfo', this.userData);
    }
  }
}
</script>

<style>

</style>
