<template>
  <div v-if="flag">

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Seleccione los Servicios del Catalogo
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: Contact Options -->
        <b-col
          cols="12"
          md="12"
        >
        
          <b-form-group
            label="Catalogo de Servicios"
            label-for="contact-options"
            label-class="mb-1"
            style="width: 100%;"
          >
            <b-form-checkbox v-for="(option, indexOp) in computedServicesList" v-bind:key="indexOp"
      :value="indexOp"
      name="flavourMaterial"
      class="col-3 materialCheckbox"
      inline @input="updateCategoriesRelated(indexOp)"
    ><span  style="font-weight: bold; font-size: 0.9em !important;">{{ option.name }}</span></b-form-checkbox>
            
          </b-form-group>
        </b-col>
      </b-row>


      <b-row class="mt-2">
        <b-col class="mt-2" v-if="userData.id !== 0">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateUserData"
          >
            Guardar Cambios
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col class="mt-2" v-else>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateUserData"
          >
            Agregar Informacion Nuevo Usuario
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>

import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormCheckbox
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ref, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
export default {
  components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormCheckbox
  },
  props: {

    userData: {}

  },
  setup() {
    let servicesList = ref([]);
    const computedServicesList = computed({
      get: () => { return servicesList.value; },
      set: (newList) => {
        servicesList.value = newList;
      }
    });
    let userDataInfo = {};
    let flag = false;
    const ecommerce = new ECommerce();
    const languageOptions = [
      'English',
      'Español',
      'French',
      'Russian',
      'German',
      'Arabic',
      'Sanskrit',
    ]

    const genderOptions = [
      { text: 'Masculino', value: 'male' },
      { text: 'Femenino', value: 'female' },
      { text: 'Abierto', value: 'no' },
    ]

    const contactOptionsOptions = [{
      name: 'Nombre'
    }]

    return {
      userDataInfo,
      languageOptions,
      genderOptions,
      contactOptionsOptions, 
      flag, ecommerce, computedServicesList
    }
  },
  mounted() {
    this.userDataInfo = this.userData.user_info;

    this.ecommerce.getCategoryServices(-1).then((servicesList) => {
this.computedServicesList = servicesList;
this.computedServicesList.forEach((service) => {
  service.is_active = false;
})
this.flag = true;
      console.log('terreno ', this.computedServicesList);
// document.getElementById('modal-primary').addEventListener("hidden.bs.modal", alert('relac'));

});
  },
  methods: {
    setNewLocation(newLocation) {
        this.userData.location.lat = newLocation.lat;
        this.userData.location.lng = newLocation.lng;
        console.log(this.userData);
      }, 
    updateCategoriesRelated(eventx) {

      this.computedServicesList[eventx].is_active = !this.computedServicesList[eventx].is_active;
      console.log('hpta ', this.computedServicesList, eventx);
    },
    updateUserData(eventx) {
      console.log('si');
      this.userDataInfo.categories = [];

      this.computedServicesList.forEach((service) => {
        if(service.is_active) {
          this.userDataInfo.categories.push(service.id_service);
        }
      })

      this.$emit('updateUserInfo', this.userDataInfo);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
