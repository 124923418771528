<template>
  <component :is="userData === undefined ? 'div' : 'b-card'" class="customersListStyle">

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <div class="pricing-free-trial startTrainingHeaderStyle">


      <b-row>
        <b-col xl="12">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-2" style="margin-left: 10px !important;">
              <b-row>
                <b-col xl="12" cols="12">
                  <b-row>
                    <b-col xl="2" style="margin-top: -40px;">
                      <b-img fluid :src="require('@/assets/images/logo/logo2.png')" class="pricing-trial-img"
                        alt="svg img" style="height: 120px; width: 120px" />
                    </b-col>
                    <b-col xl="7" class="mt-0">
                      <div class="text-primary" style="margin-top: -30px !important;">
                        CONTROL DE USUARIOS <b>LAVARCLICK !</b> <span class="text-secondary">Administra Todos los
                          Clientes, Aliados y Administradores de la Plataforma</span>
                      </div>
                      <!-- <b-form-group class="blog-search">

<b-input-group class="input-group-merge">
<b-form-input
id="search-input"
v-model="search_query"
placeholder="Buscar categoria o servicio ..."
/>
<b-input-group-append
class="cursor-pointer"
is-text
>
<feather-icon
icon="SearchIcon"
/>
</b-input-group-append>
</b-input-group>
</b-form-group> -->
                    </b-col>
                    <!-- <b-col xl="3" style="padding-top: 0px !important; font-size: 0.8em !important;">
                    ó Crea Una <b-img 
              fluid
              :src="require('@/assets/images/slider/addcat.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 60px; margin-top: 70px !important;"
              v-b-modal.modal-primary-form
            />
                    
                  </b-col> -->
                  </b-row>
                </b-col>
              </b-row>


              <!-- <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Buscar categoria o servicio ..."
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group> -->
            </div>

            <!-- images -->
            <div
              style="height: 10% !important; width: 50% !important; margin-top: -20px !important; margin-right: -80px !important;">
              Crear Nuevo Usuario <b-img fluid :src="require('@/assets/images/slider/addcat.png')"
                class="pricing-trial-img" alt="svg img" style="height: 60px; margin-top: 70px !important;"
                v-b-modal.modal-primary />
              <b-img fluid :src="require('@/assets/images/illustration/team2.png')" class="pricing-trial-img"
                alt="svg img" style="height: 150px; padding-left: 50px !important;" />
            </div>

            <!--/ images -->
          </div>
        </b-col>
      </b-row>

    </div>
    <b-overlay :show="loading" rounded="sm">

      <div style="margin-top: -120px !important; overflow-y: hidden !important; overflow-x: hidden !important;">
        <!-- <h3>- Listado Actual Clientes -</h3> -->
        <b-row>
          <b-col xl="12">


            <b-row>
              <b-col xl="6">
                <div style="height: 100% !important;">
                  <users-general-list @updateUserStatus="updateUserStatus" @describeUser="describeUser" v-if="userData.role === 'admin' && flag"
                    :employeePartners="employeePartners" />
                </div>
              </b-col>
              <b-col v-if="flagDescribeUser" xl="6">
                <b-row>
                  <!-- <b-col xl="3">
                    <div style="width: 100%; text-align: center;">
                      <b-badge variant="primary" class="badge-glow">
                        {{ computedUserDetail.type }}
                      </b-badge>

                      <b-badge variant="success" class="badge-glow" v-if="computedUserDetail.remember_token">
                        ACTIVO
                      </b-badge>
                      <b-badge v-else variant="danger" class="badge-glow">
                        INACTIVO
                      </b-badge>
                      <button @click="closeDescribeUser">cerrar</button>
                    </div>
                    <div style="padding-top: 30px !important;">
                      <b-img v-if="computedUserDetail.type.lastIndexOf('CLIENTE') !== -1" :src="'/img/mia.12bac2bd.png'"
                        rounded fluid alt="profile photo" style="margin-top: -20px !important;" />
                      <b-img v-else :src="'/logo2.png'" rounded fluid alt="profile photo"
                        style="margin-top: -20px !important;" />


                    </div>
                  </b-col> -->
                  <b-col xl="5">
                    <div style="width: 100%; text-align: center;">
                      <b-badge variant="primary" class="badge-glow">
                        {{ computedUserDetail.type }}
                      </b-badge>

                      <b-badge variant="success" class="badge-glow" v-if="computedUserDetail.remember_token">
                        ACTIVO
                      </b-badge>
                      <b-badge v-else variant="danger" class="badge-glow">
                        INACTIVO
                      </b-badge>
                      <button @click="closeDescribeUser">cerrar</button>
                    </div>
                    <div
                      style="border-style: solid; border-width: 0.1em; width: 100%; padding: 0.7em; border-radius: 15px;">
                      <b-form-group class="blog-search">

                        <b-input-group class="input-group-merge">
                          <b-input-group-append class="cursor-pointer" is-text>
                            <feather-icon icon="UsersIcon" />
                            <span v-if="computedUserDetail.type === 'customer'"
                              style="padding-left: 5px !important;">Nombre(s)</span>
                            <span v-else style="padding-left: 5px !important;">Razon Social</span>
                          </b-input-group-append>

                          <b-form-input id="search-input" placeholder="" v-model="computedUserDetail.names" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group class="blog-search">

                        <b-input-group class="input-group-merge">
                          <b-input-group-append class="cursor-pointer" is-text>
                            <feather-icon icon="UsersIcon" />
                            <span v-if="computedUserDetail.type === 'customer'"
                              style="padding-left: 5px !important;">Apellido(s)</span>
                            <span v-else style="padding-left: 5px !important;">NIT</span>
                          </b-input-group-append>

                          <b-form-input id="search-input" placeholder="" v-model="computedUserDetail.surnames" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group class="blog-search">

                        <b-input-group class="input-group-merge">
                          <b-input-group-append class="cursor-pointer" is-text>
                            <feather-icon icon="UsersIcon" /> <span style="padding-left: 5px !important;">E-Mail</span>
                          </b-input-group-append>

                          <b-form-input id="search-input" placeholder="" v-model="computedUserDetail.email" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group class="blog-search">

                        <b-input-group class="input-group-merge">
                          <b-input-group-append class="cursor-pointer" is-text>
                            <feather-icon icon="UsersIcon" /> <span style="padding-left: 5px !important;">Direccion</span>
                          </b-input-group-append>

                          <b-form-input id="search-input" placeholder="" v-model="computedUserDetail.address" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group class="blog-search">

                        <b-input-group class="input-group-merge">
                          <b-input-group-append class="cursor-pointer" is-text>
                            <feather-icon icon="UsersIcon" /> <span style="padding-left: 5px !important;">Ciudad</span>
                          </b-input-group-append>

                          <b-form-input id="search-input" placeholder="Filtrar Dashboard x Servicio" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <v-select style="width: 100% !important; float: left;" label="name" :options="allServicesLlist"
                            @input="addServiceToProvider" />
                      </b-form-group>
                    </div>

                  </b-col>
                  <b-col xl="7" style="width: 100% !important;">
                    <table class="serviceTableStyle">
                      <tr>
                        <th style="text-align: center !important; padding: 5px;">UBICACION</th>
                      </tr>
                      <tr>
                        <td style="height: 220px !important;">
                          <MapBasicPosition :locate_service_provider="{
                            customer: {
                              location: {
                                lat: computedUserDetail.location.lat,
                                lng: computedUserDetail.location.lng,
                              },
                              full_name: computedUserDetail.names + ' ' + computedUserDetail.surnames
                            }
                          }" />
                        </td>
                      </tr>
                    </table>
                  </b-col>

                </b-row>
                <b-row v-if="computedUserDetail.type.lastIndexOf('AFILIADO') !== -1" style="height: 150px;">
                  
                  <b-col xl="12" style="overflow-y: auto;">
                    <table class="serviceTableStyle" style="margin-top: 2% !important;">
                      <tr>
                        <th colspan="4" style="text-align: center !important;">SERVICIOS DISPONIBLES</th>
                      </tr>
                     
                      <tr v-for="(catalog, indexCat) in computedProviderCatalog" v-bind:key="indexCat">
                        <td colspan="3">{{ catalog.label }}</td>
                        <td><img src="/img/del.png" @click="updateProviderCatalog(catalog, iindexCat)" /></td>
                      </tr>
                      <!-- <tr>
              <th>ID</th><th>ESTADO</th><th style="">FECHA</th><th>TICKET</th>
            </tr>
          <tr v-for="(ticket, indTick) in ticketsReport" v-bind:key="indTick">
              <td>{{ticket.id_ticket}}</td><td>{{ticket.status}}</td><td style="">{{ticket.created_at}}</td><td>{{ticket.ticket}}</td>
            </tr> -->
                    </table>
                  </b-col>
                </b-row>
                <b-row style="width: 100%; height: 450px;">
            <b-col>
              <b-card
                style="height: 400px !important; border-style: solid; margin-top: 3%; border-color: #000000; border-width: 0.1em; border-radius: 15px; overflow-y: auto !important;">
                <b-card-title>
                  Cliente: <span style="font-weight: bold; font-size: 16px;">{{ selectedCustomer.customer.full_name }}
                  </span> Total Ordenes: <span
                    style="font-weight: bold; font-size: 16px;">{{ selectedCustomer.orders.length }}</span>
                </b-card-title>
                <!-- <PolarAreaChart :chartjsData="polarChart" /> -->


                <table class="serviceTableStyle" v-if="flagOrderByCustomer">
                  <tr>
                    <th style="width: 30%;">ORDER INFO</th>
                    <th style="width: 50%;">ITEMS DE LA ORDER</th>
                    <th style="width: 20%;">ACCIONES</th>
                  </tr>
                  <tr v-for="(customerOrder, indexCus) in selectedCustomer.orders" v-bind:key="indexCus">
                    <td><span>ORDER-000{{ customerOrder.id_order }}</span><br />
                      Estado: <span
                        style="font-weight: bold; font-size: 16px;">{{ getOrderStatus(customerOrder.timeline) }}</span><br />
                      Valor: <span style="font-weight: bold; font-size: 16px;">{{
                        getTotals(customerOrder.logistic.collect) }} COP</span><br />

                      <FeatherIcon icon="ClockIcon" class="text-success" size="20" /> - Cotizacion Creada <span
                        style="font-weight: bold; font-size: 16px;">{{ customerOrder.timeline.created_at ? formatDate(new
                          Date(customerOrder.timeline.created_at)) : '' }}</span><br />
                      <FeatherIcon icon="ClockIcon" class="text-success" size="20" /> - Solicitud Ejecutada
                      {{ customerOrder.timeline.request_date ? formatDate(new Date(customerOrder.timeline.request_date)) :
                        '' }}<br />
                      {{ customerOrder.timeline.assignment_date ? formatDate(new
                        Date(customerOrder.timeline.assignment_date)) : '' }}<br />
                      {{ customerOrder.timeline.accept_date ? formatDate(new Date(customerOrder.timeline.accept_date)) :
                        '' }}<br />
                    </td>
                    <td>
                      <table>
                        <tr>
                          <th style="width: 10%;">COD</th>
                          <th style="width: 20%;">SERVICIO</th>
                          <th style="width: 5%;">CANT</th>
                          <th style="width: 8%;">Vr UNT</th>
                          <th style="width: 5%;">Vr TOTAL</th>
                        </tr>
                        <tr v-for="(item, indexI) in customerOrder.logistic.collect.items" v-bind:key="indexI">
                          <td>
                            {{ item.code }}
                          </td>
                          <td>
                            {{ item.name }}
                          </td>
                          <td>
                            {{ item.quantity }}
                          </td>
                          <td>
                            {{ item.price }}
                          </td>
                          <td>
                            {{ item.quantity * item.price }}
                          </td>
                        </tr>
                        <tr>
                          <th colspan="5">OBSERVACIONES</th>
                        </tr>
                        <tr>
                          <td colspan="5">{{ customerOrder.comment }}</td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <div class="text-nowrap"
                        style="border-top-style: solid !important; border-bottom-style: solid !important; padding-bottom: 15px !important; padding-top: 15px !important;">
                        <!-- <h3>Acciones de la Order</h3> -->
                        Enviar Orden <feather-icon icon="SendIcon" class="cursor-pointer" size="20" />
                        <b-tooltip title="Enviar" class="cursor-pointer"
                          :target="`invoice-row-${customerOrder.id_order}-send-icon`" /><br />

                        Ver Orden <feather-icon icon="EyeIcon" size="20" class="mx-1"
                          @click="describeOrder(customerOrder)" v-b-modal.modal-primary-order />
                        <b-tooltip title="Vista Detallada"
                          :target="`invoice-row-${customerOrder.id_order}-preview-icon`" /><br />

                        <!-- Dropdown -->
                        Estado Orden <b-dropdown variant="link" toggle-class="p-0" no-caret
                          :right="$store.state.appConfig.isRTL">

                          <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                          </template>
                          <b-dropdown-item>
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">Habilitar</span>
                          </b-dropdown-item>
                          <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: customerOrder.id_order } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Deshabilitar</span>
                          </b-dropdown-item>
                          <!-- <b-dropdown-item>
                <feather-icon icon="CopyIcon" />
                <span class="align-middle ml-50">Duplicate</span>
              </b-dropdown-item> -->
                        </b-dropdown>
                      </div>
                    </td>
                  </tr>
                  <!-- <tr v-for="(serviceItem, indSI) in computedCart" v-bind:key="indSI">
            <td>{{serviceItem.code}}</td><td>{{serviceItem.name}}</td><td>{{serviceItem.quantity}}</td><td></td>
          </tr> -->

                </table>
              </b-card>
            </b-col>
          </b-row>

               

                <!-- <b-row>
                  <b-col v-if="!flagCharts">
                    <div style="width: 80% !important;">
                      <BarChart :chartjsData="latestBarChart" />
                    </div>
                    <div style="width: 80% !important;">
                      <BarChart :chartjsData="latestBarChart" />
                    </div>
                  </b-col>
                </b-row> -->
              </b-col>
              <b-col v-if="flagCharts" style="width: 300px;">
                <div style="width: 100% !important;">
                  <BarChart :chartjsData="latestBarChart" />
                </div>
                <div style="width: 100% !important;">
                  <BarChart :chartjsData="latestBarChart" />
                </div>
              </b-col>

            </b-row>
            <!-- <b-row v-if="flagCharts">
      <b-col>
        <BarChart :chartjsData="latestBarChart" />
      </b-col>
    </b-row>
    <b-row v-if="flagCharts">
      <b-col>
        <BarChart :chartjsData="latestBarChart" />
      </b-col>
    </b-row> -->

          </b-col>

        </b-row>

      </div>
      <div v-if="flagDescribeOrder">

<OrderDescriptor :order="computedSelectedOrder" @closeOrderDescriptor="closeOrderDescriptor" />
</div>

    </b-overlay>

    <!-- <div class="text-center text-md-left mt-3" style="margin-left: 0px !important; margin-top: -40px !important; padding-bottom: 15px !important;">hola -->
    <!-- <h3 class="text-primary">
                FORMULARIO NUEVO CLIENTES<br/>Crear un Nuevo Cliente
              </h3> -->
    <!-- <h5>Listado de Clientes</h5> -->

    <!-- </div> -->
    <NewUserForm @updateUser="updateUser(...arguments)" />

  </component>
</template>

<script>
import {
  BTab, BTabs, BOverlay, BDropdownItem, BTooltip, BDropdown, BCardTitle, BBadge, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BCard, BAlert, BLink, BImg, BRow, BCol, BModal, VBModal, BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select';
import { ref, onUnmounted, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from './userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabSocial from './UserEditTabSocial.vue'
import UsersGeneralList from './UsersGeneralList.vue'
import User from '@/lavarclick/ecommerce/User.ts';
import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts';
import Ripple from 'vue-ripple-directive';
import NewUserForm from './NewUserForm.vue';
import MapBasicPosition from '@/lavarclick/components/maps/MapBasicPosition.vue';
import UserStats from './UserStats.vue';
import BarChart from '../charts/BarChart.vue';
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
import ToastContent from '@/lavarclick/components/utils/ToastContent.vue';
import OrderDescriptor from '@/lavarclick/components/services/OrderDescriptor.vue';
export default {
  components: {
    BRow, BCol, BDropdownItem, BDropdown, BTooltip, NewUserForm, BModal, VBModal, BCardBody, BOverlay, BCardTitle, OrderDescriptor,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UsersGeneralList,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial, vSelect,
    BImg, MapBasicPosition, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, UserStats, BarChart, BBadge, ToastContent
  },
  setup(props, { emit }) {
    let providerCatalog = ref([]);
    const computedProviderCatalog = computed({
      get: () => { return providerCatalog.value },
      set: (newCatalog) => {
        providerCatalog.value = newCatalog;
      }
    })


    let flagOrderByCustomer = ref(false);
    let flagDescribeOrder = ref(false);
    let selectedCustomer = {
        customer: {

        },
        orders: []
      };
    const accountsManager = new AccountsManager();

    let flag = false;
    const newUserObject = new User();

    let employeePartners = [];
    let customerAreasList = [];
    let allServicesLlist = [];
    const ecommerce = new ECommerce();
    let loading = ref(true);

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const userData = JSON.parse(localStorage.getItem('userData'));
    let latestBarChart = {};
    let chartColors = {
      primaryColorShade: '#836AF9',
      yellowColor: '#ffe800',
      successColorShade: '#28dac6',
      warningColorShade: '#ffe802',
      dangerColorShade: '#b32034',
      warningLightColor: '#FDAC34',
      infoColorShade: '#299AFF',
      greyColor: '#4F5D70',
      blueColor: '#2c9aff',
      blueLightColor: '#84D0FF',
      greyLightColor: '#EDF1F4',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      lineChartPrimary: '#666ee8',
      lineChartDanger: '#ff4961',
      labelColor: '#6e6b7b',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',
    };
    let ticketsReport = [{
      id_ticket: 1,
      ticket: 'Tuve un problema con un pantalon',
      solution: '',
      created_at: '2022-10-09',
      orders_related: [{
        id_order: 1
      }]
    }];

    let ordersReport = [{

      id_order: 1,
      billing: {
        price: 25000,
      },
      timeline: {
        created_at: new Date(),
        completed_at: new Date(),
        paused_at: new Date(),
        deleted_at: new Date()
      },
      items: [{
        id_category: 1,
        category_name: 'Lavanderia',
        id_service: 1,
        service_name: 'Lavanderia x Prendas',
        service_items: [{
          name: 'Pantalon',
          qty: 2
        }, {
          name: 'Camisa',
          qty: 4
        }],
        billing: {
          price: 25000,

        }
      }, {
        id_category: 3,
        category_name: 'Lavado Vehiculos',
        id_service: 1,
        service_name: 'Lavado de Camioneta',
        service_items: [{
          name: 'Camioneta',
          qty: 2
        }],
        billing: {
          price: 150000,

        }
      }]
    }];

    let userDetail = ref({
      company_area: {
        name: ''
      }
    });

    const computedUserDetail = computed({
      get() {
        return userDetail.value;
      },
      set(userDetailed) {
        userDetail.value = userDetailed;
      }
    });

    let flagCharts = false;
    let flagDescribeUser = false;

    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    return {
      userData,
      newUserObject,
      accountsManager,
      UsersGeneralList,
      employeePartners,
      flag,
      customerAreasList,
      ordersReport,
      ticketsReport,
      latestBarChart,
      chartColors, flagOrderByCustomer,
      flagCharts,
      allServicesLlist,
      ecommerce,
      userDetail,
      computedUserDetail,
      flagDescribeUser,
      loading, flagDescribeOrder, selectedCustomer, computedProviderCatalog
    }
  },
  mounted() {

    this.newUserObject['account'] = ['cscsdc', '', ''];

    this.newUserObject.customer = this.userData.customer;

    this.accountsManager.getCustomerAreas(1).then((customerAreas) => {

      this.customerAreasList = customerAreas;

      this.ecommerce.getECommerceCustomer({

        get_all: true,
        get_active: true,
        get_customers_by_city: 0,
        id_employee: 0

      }).then((customersList) => {

        this.ecommerce.getCategoryServices(-1).then((activeCatServices) => {

          if (activeCatServices.length > 0) {
            this.allServicesLlist = activeCatServices;
          } else {
            this.allServicesLlist = [];
          }

          this.flag = true;
          this.loading = false;

        });
        console.log('veinte ', customersList);
        this.employeePartners = customersList;


      });

    });


    this.latestBarChart = {
      data: {
        labels: ['Aprobados', 'No Aprobados', 'Aprobados', 'No Aprobados', 'Aprobados', 'No Aprobados'],
        datasets: [
          {
            data: [31, 56, 83, 72, 15, 46],
            backgroundColor: [
              this.chartColors.successColorShade,
              this.chartColors.dangerColorShade,
              this.chartColors.successColorShade,
              this.chartColors.dangerColorShade,
              this.chartColors.successColorShade,
              this.chartColors.dangerColorShade
            ],

            borderColor: 'transparent',
          },
        ],
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: this.chartColors.tooltipShadow,
          backgroundColor: "#FFFFFF",
          titleFontColor: "#000000",
          bodyFontColor: "#000000",
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 5,
                min: 0,
                max: 100,
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
        },
      },
    };

    this.flagCharts = true;

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {

    updateProviderCatalog(providerCatalogService, indexCat) {
this.accountsManager.updateProviderCatalog(providerCatalogService).then((catalogResult) => {
  this.computedProviderCatalog.splice(indexCat, 1);
  this.$toast({
                component: ToastContent,
                position: 'b-toaster-top-center',
                props: {
                  title: `Se Ha Actualizado El Catalogo del Afiliado`,
                  icon: 'CoffeeIcon',
                  variant: 'default',
                  text: `STATUS`,
                  bgcolor: '#55a64b'

                },
                delay: 15000
              });
    
});

    },
    describeOrder(order) {
      this.computedSelectedOrder = order;
      this.flagDescribeOrder = true;
    },
    closeOrderDescriptor(eventx) {


},

    getOrderStatus(timeline) {

let status = '';

if (timeline.created_at) {
  status = 'Solicitud Creada';

} else if (timeline.request_at) {
  status = 'Solicitud Ejecutada';
}

return status;
},
getTotals(collect) {
      console.log('ok');
      let totalB = 0;
      new Promise((resolve, reject) => {

        collect.items.forEach((item, indexIt) => {
          totalB += item.price * item.quantity;
          if (indexIt === (collect.items.length - 1)) {
            resolve(true);
          }
        });
      }).then((result) => {

        console.log('termi');

      });
      return totalB;
    },

    addServiceToProvider(eventx) {

      this.ecommerce.addServiceToProvider({
        id_provider: this.computedUserDetail.id_scanclick_users,
        id_service: eventx.id_service
      }).then((providerUpdated) => {
        this.$toast({
                component: ToastContent,
                position: 'b-toaster-top-center',
                props: {
                  title: `Se Ha Agregado Con Exito el Servicio al catalogo del Afiliado`,
                  icon: 'CoffeeIcon',
                  variant: 'default',
                  text: `Catalogo Actualizado !`,
                  bgcolor: '#55a64b'

                },
                delay: 15000
              });
        console.log('termine ', providerUpdated);

      });
    },

    getProviderOrders(customer) {
            this.loading = true;

     // this.userType = JSON.parse(type.value);
     this.accountsManager.getProviderCatalog({
      id_provider: customer.id_scanclick_users
     }).then((pCatalog) => {
      this.computedProviderCatalog = pCatalog;
     })
     
  //   this.loading = true;
      this.orderList = [];
      this.flagOrderByCustomer = false;
      //  this.flagResumeType = false;
        this.ecommerce.getECommerceProvider({
          get_all: false,
          get_active: false,
          get_providers_by_city: 0,
          id_employee: customer.id_scanclick_users
        }).then((ecommerceList) => {
          // this.computedCustomerList = ecommerceList;

         
            this.ecommerce.getProviderOrders({
              get_all: true,
              get_order_id: 0,
              get_quotes: true,
              get_customer_id: ecommerceList[0].id_provider
            }).then((categoriesList) => {

              console.log('captu ', categoriesList);

        if (categoriesList.length === 0) {

          this.loading = false;

        } else {

          categoriesList.forEach((order) => {

            order.order.id_order = order.id_order;
            order.order.timeline = order.timeline;
            this.orderList.push(order.order);

            if (this.orderList.length === categoriesList.length) {
              this.selectedCustomer = {
                customer: customer,
                orders: this.orderList
              };
              console.log('parapara ss', {
                customer: customer,
                orders: this.orderList
              });
              this.loading = false;
              this.flagOrderByCustomer = true;
            }
          });

        }

            });
            });


    },

    getCustomerOrdersList(customer) {

      this.loading = true;
      this.orderList = [];
      this.flagOrderByCustomer = false;

      console.log({
        get_all: true,
        get_order_id: 0,
        get_quotes: false,
        get_customer_id: customer.id_customer
      });

      this.ecommerce.getECommerceOrders({
        get_all: true,
        get_order_id: 0,
        get_quotes: false,
        get_customer_id: customer.id_customer
      }).then((categoriesList) => {

        console.log('captu ', categoriesList);

        if (categoriesList.length === 0) {

          this.loading = false;

        } else {

          categoriesList.forEach((order) => {

            order.order.id_order = order.id_order;
            order.order.timeline = order.timeline;
            this.orderList.push(order.order);

            if (this.orderList.length === categoriesList.length) {
              this.selectedCustomer = {
                customer: customer,
                orders: this.orderList
              };
              console.log('parapara ss', {
                customer: customer,
                orders: this.orderList
              });
              this.loading = false;
              this.flagOrderByCustomer = true;
            }
          });

        }
      });
      // console.log('econmia ', customer);
    },

    updateUserStatus(newUserStatus) {

      this.accountsManager.updateUserStatus(newUserStatus).then((result) => {
        this.$toast({
                component: ToastContent,
                position: 'b-toaster-top-center',
                props: {
                  title: `Se Ha Actualizado el Estado del Usuario`,
                  icon: 'CoffeeIcon',
                  variant: 'default',
                  text: `STATUS`,
                  bgcolor: '#55a64b'

                },
                delay: 15000
              });
      })
     console.log(newUserStatus)
    },

    describeUser(user) {

      console.log('milo', user);
      

      if (user.type.lastIndexOf('CLIENTE') !== -1) {
        this.getCustomerOrdersList(user);
        this.computedUserDetail = user;
        console.log('fiscalia ', this.computedUserDetail);
        this.flagDescribeUser = true;
        this.flagCharts = false;

      } else if (user.type.lastIndexOf('AFILIADO') != -1) {
this.getProviderOrders(user);
this.computedUserDetail = user;
        console.log('fiscalia ', this.computedUserDetail);
        this.flagDescribeUser = true;
        this.flagCharts = false;
        console.log('afi', user);

      }

      // this.accountsManager.getCustomerLocations(


    },
    closeDescribeUser() {

  this.flagDescribeUser = false;
  this.flagCharts = true;


},

    formatDate(date) {

      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

    },

    updateUser(updateInfo) {

      this.updateUserInfo(updateInfo.infoTypeIndex, updateInfo.userData);

    },
    updateUserInfo(infoTypeIndex, userData) {

      const newEmployee = userData;

      switch (infoTypeIndex) {

        case 1:
          this.newUserObject.account = userData.account;
//          userData.categories = this.newUserObject.categories;
          console.log('messi');
          newEmployee.surname = userData.fullName;
          newEmployee.customer_id = this.userData.customer.id_scanclick_customers;
          newEmployee.company = this.userData.customer.name;
          // this.newUserObject.categories = userData.user_info.categories;
          // this.newUserObject.user_info = userData.user_info;
          this.flag = false;

          this.accountsManager.createEmployee(newEmployee).then((createEmployeeResult) => {
            this.$bvModal.hide('modal-primary');
            // this.accountsManager.getEmployeePartners({
            //   id_current_user: JSON.parse(localStorage.getItem('userData')).id,
            //   id_customer: 1

            // }).then((partners) => {

              // this.employeePartners = partners;
              this.$toast({
                component: ToastContent,
                position: 'b-toaster-top-center',
                props: {
                  title: `Se Ha Creado Con Exito el Nuevo Usuario ${newEmployee.username} ${newEmployee.surname}`,
                  icon: 'CoffeeIcon',
                  variant: 'default',
                  text: `Nuevo Usuario Registrado !`,
                  bgcolor: '#55a64b'

                },
                delay: 15000
              });
              this.newUserObject['account'] = ['cscsdc', '', ''];

this.newUserObject.customer = this.userData.customer;

this.accountsManager.getCustomerAreas(1).then((customerAreas) => {

  this.customerAreasList = customerAreas;

  this.ecommerce.getECommerceCustomer({

    get_all: true,
    get_active: true,
    get_customers_by_city: 0,
    id_employee: 0

  }).then((customersList) => {

    this.ecommerce.getCategoryServices(-1).then((activeCatServices) => {

      if (activeCatServices.length > 0) {
        this.allServicesLlist = activeCatServices;
      } else {
        this.allServicesLlist = [];
      }

      this.flag = true;
      this.loading = false;

    });
    console.log('veinte ', customersList);
    this.employeePartners = customersList;


  });

});
         //     this.flag = true;

            });
          // });
          break;
        case 2:
          this.newUserObject.categories = userData.user_info.categories;
          this.newUserObject.user_info = userData.user_info;
          delete userData.user_info.categories;
          break;

        case 3:
          break;
      }
      console.log(this.newUserObject);
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'MavenPro';
  src: url('/assets/fonts/MavenPro-VariableFont_wght.ttf');
}

.customersListStyle {
  font-family: 'MavenPro';
  padding-bottom: 3%;
}

.customersListTitleStyle {
  font-size: 20px !important;
  margin-top: -20px !important;
}

.startTrainingHeaderStyle {
  height: 120px !important;
  font-size: 20px !important;
  text-align: center !important;
  margin-top: 0px !important;
  width: 100% !important;
  font-family: 'MavenPro' !important;
}

.serviceTableStyle {
  margin-left: 0px !important;
  border-collapse: collapse;
  font-family: 'MavenPro' !important;
  border-radius: 10px !important;
  width: 100% !important;
  margin-top: 0px !important;
  overflow-y: auto !important;
  height: 100% !important;
}


.serviceTableStyle th {
  border: #4e95f4 0px solid;
  font-size: 14px !important;
  color: #ffffff !important;
  background-color: #6d36ac !important;
  text-align: justify !important;
  font-family: 'MavenPro' !important;
  height: 20px !important;
  padding-left: 15px !important;
}

.serviceTableStyle td {
  border: #4e95f4 0px solid;
  text-align: justify !important;
  font-family: 'MavenPro' !important;
  padding-left: 15px !important;
  vertical-align: middle !important;


}

/* provide some minimal visual accomodation for IE8 and below */
.serviceTableStyle tr {
  background: #b8d1f3;
}

/*  Define the background color for all the ODD background rows  */
.serviceTableStyle tr:nth-child(odd) {
  background: #f2edf7;
}

/*  Define the background color for all the EVEN background rows  */
.serviceTableStyle tr:nth-child(even) {
  background: #f2faf0;
}
</style>
