<template>
    <b-card
      no-body
      class="card-statistics" style="border-style: solid; border-width: 0.1em; border-radius: 15px;"
    >
      <!-- <b-card-header>
        <b-card-title>Estadisticas !</b-card-title>
      </b-card-header> -->
      <b-card-body class="">
        <b-row v-for="item in statisticsItems"
            :key="item.icon">
          <b-col
            
            
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside
  
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="40"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <div class="font-weight-bolder mb-0 text-left row" >
                  <span style="color: #000000 !important; font-size: 16px !important; padding-right: 5px !important;">{{ item.title }}</span>
                  <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
                </div>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
  } from 'bootstrap-vue'
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
    },
    data() {
      return {
        statisticsItems: [
          {
            icon: 'PocketIcon',
            color: 'light-primary',
            title: '3',
            subtitle: 'Total Servicios',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'BoxIcon',
            color: 'light-success',
            title: '3',
            subtitle: 'Servicios Activos',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'BoxIcon',
            color: 'light-danger',
            title: '0',
            subtitle: 'Servicios Inactivos',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            title: '15',
            subtitle: 'Este Mes !',
            customClass: '',
          },
        ],
      }
    },
  }
  </script>
  